.scss-footer {
  margin-top: 15vh;
  margin-bottom: 5vh;
}

.scss-footer-body {
  display: flex;
  flex-direction: column;
}
.scss-footer-contactinfo {
  margin-bottom: 20px;
}
.scss-footer-text {
  font-size: large;
  color: #fff;
  text-decoration-style: wavy;
  &:hover {
    color: $primary-bootstrap-color;
  }
}

.scss-footer-logo-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.scss-footer-skills {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}
.scss-footer-skill-type {
  width: 40vw;
}
.scss-language-logo {
  width: 30px;
  box-sizing: border-box;
  margin-left: 10px;
}

@include media-breakpoint-up(md) {
  .scss-footer-text {
    font-size: larger;
  }
  .scss-language-logo {
    width: 35px;
    box-sizing: border-box;
    margin-right: 5px;
  }
}



@include media-breakpoint-up(lg) {
  .scss-footer-body {
    flex-direction: row;
  }
  .scss-footer-text {
    font-size: x-large;
  }
  .scss-language-logo {
    width: 45px;
    box-sizing: border-box;
    margin-right: 5px;
  }
  .scss-footer-skill-type {
    width: auto;
  }
  .scss-footer-logo-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

