.scss-what-main-section {
  margin-bottom: 80px;
}

.scss-what-main {
  display: flex;
  flex-direction: column;
}

.scss-what-article {
  margin-top: 60px;
}

.scss-what-experience-logo, .scss-what-education-logo {
  width: 40px;
  box-sizing: border-box;
}

.scss-what-education-title, .scss-what-education-article {
  text-align: right;
}

.scss-what-education-project-button-section {
  display: flex;
  justify-content: flex-end;
}

.scss-what-education-project-button {
  margin-left: 20px;
  text-decoration: underline;
  text-decoration-color: $secondary-color-background;
  cursor: grab;
  &:hover {
    color: $secondary-color-background;
  }
  &:active {
    cursor: grabbing;
  }
}
.scss-what-education-project-image {
  width: 300px;
  box-sizing: border-box;
  border: 4px solid white;
}
.scss-what-education-project-links {
  color: #fff;
  text-decoration-color: $primary-bootstrap-color;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
}


@include media-breakpoint-up(lg) {
  .scss-what-main {
    flex-direction: row;
    justify-content: space-between;
  }
  .scss-what-experience-education-section {
    max-width: 45%;
  }
  .scss-what-main-section {
    margin-bottom: 0px;
  }
  .scss-what-main-title {
    text-decoration: underline;
    text-decoration-thickness: 4px;
  }
}