.scss-modal-button {
  background-color: $primary-color-background;
  border: none;
  &:hover {
    background-color: $primary-bootstrap-color;
  }
}


.scss-modal-body-text {
  font-family: $secondary-font;
  font-size: 1.5rem;
}
.scss-modal-certificate-img, .scss-modal-body-text {
  width: 280px;
  display: block;
}

.scss-modal-highlighted-text {
  color: $primary-color-background;
}

@include media-breakpoint-up(sm) {
  .scss-modal-certificate-img, .scss-modal-body-text {
    width: 320px;
    box-sizing: border-box;
    }
  }
@include media-breakpoint-up(md) {
.scss-modal-certificate-img, .scss-modal-body-text {
  width: 464px;
  box-sizing: border-box;
  }
}


@include media-breakpoint-up(xl) {
  .scss-modal-button {
    margin: 0 4vw 0 4vw;
  }
}
