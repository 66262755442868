$primary-color-background: #a530f9;
$secondary-color-background: #ff791f;
$primary-bootstrap-color: #0d6efd;



$primary-color-font: #fff;
$secondary-color-font: #000;




body {
  background-color: $primary-color-background;
  color: $primary-color-font;
}

.scss-secondary-color-font {
  color: $secondary-color-font;
}