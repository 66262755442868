.scss-home-aboutme-img {
  width: 20vw;
}

.scss-qualifications-img {
  margin-top: 15px;
  margin-bottom: 30px;
  width: 80px;
  box-sizing: border-box;
}
.scss-qualifications-img-google {
  margin-top: 15px;
  margin-bottom: 30px;
  width: 60px;
  box-sizing: border-box;
}