.scss-offcanvas-text {
  font-family: $secondary-font;
}
.scss-offcanvas-address {
  text-decoration: underline;
  text-decoration-color: $secondary-color-background;
  text-decoration-thickness: 4px;
}
.scss-offcanvas-dropdown {
  border: none;
}