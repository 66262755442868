@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

@import 'modules/fonts';
@import 'modules/color';
@import 'modules/images';
@import 'modules/qualifications';
@import 'modules/nav';
@import 'modules/home';
@import 'modules/aboutme';
@import 'modules/modal';
@import 'modules/footer';
@import 'modules/whowhatwhere';
@import 'modules/who';
@import 'modules/what';
@import 'modules/where';
@import 'modules/offcanvas';



.scss-off-canvas {
  display: none;
}
@include media-breakpoint-up(lg) {
  .scss-off-canvas {
    display: block;
    margin-bottom: 60px;
  }
}