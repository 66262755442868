@import url('https://fonts.googleapis.com/css2?family=Palette+Mosaic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');



$primary-font: 'Palette Mosaic', cursive;
$secondary-font: 'Inconsolata', monospace;




body {
  font-family: $primary-font;
}