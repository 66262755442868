.scss-who-what-where-title {
  margin-top: 30px;
  margin-bottom: 60px;
  text-align: center;
}
.scss-who-what-where-para-text {
  font-family: $secondary-font;
  font-size: larger;
}


@include media-breakpoint-up(lg) {
  .scss-who-what-where-title {
    margin-bottom: 0px;
  }
}