.scss-qualifications-title {
  margin-top: 15vh;
  display: flex;
  justify-content: center;
}
.scss-qualifications-subtitle {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  text-align: center;
}

.scss-qualifications-row {
  margin-top: 100px;
}

.scss-qualifications-text {
  text-align: center;
  font-size: larger;
  font-family: $secondary-font;
}

@include media-breakpoint-up(lg) {
  .scss-qualifications-title-section {
    margin-left: 5vw;
  }
}