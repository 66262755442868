.scss-where-article {
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 40px;
}
.scss-where-article-title {
  font-family: $primary-font;
  font-size: x-large;
}

.scss-where-industry-section {
  margin-top: 40px;
}


@include media-breakpoint-up(lg) {
  .scss-where-article {
    flex-direction: row;
    justify-content: space-between;
  }
  .scss-where-industry-section {
    margin-top: 0px;
    text-align: end;
    list-style-position: inside;
  }
}