.scss-who-image-container {
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scss-who-image-bill {
  width: 60vw;
  border: 4px solid #fff;
}
.scss-who-image-dd {
  width: 60vw;
  border: 4px solid #fff;
}
.scss-who-image-bill-text {
  width: 60vw;
  text-align: center;
}
.scss-who-image-bill-text-highlight {
  text-decoration: underline;
  text-decoration-color: $secondary-color-background;
  text-decoration-thickness: 4px;
}
.scss-who-change-text {
  font-family: $primary-font;
  width: 60vw;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: x-large;
}
.scss-who-change-special-text {
  text-transform: uppercase;
  color: $secondary-color-background;
}
.scss-who-z {
  font-size: large;
}
